import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { constObj } from '../constants/library-constant';
import { BehaviorSubject, Observable } from 'rxjs';
import { Location } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class GlobalService {
  public isYearEmpty: boolean;
  public isMakeEmpty: boolean;
  public isModelEmpty: boolean;
  public isAllEmpty: boolean;
  private searchedVechicle = new BehaviorSubject<any>({
    year: { year: '2017' },
    make: { make: 'Kia' },
    model: { model: 'A3 QUATTRO', logoLocation: 'assets/A3Quattro.png' },
  });
  public vehicleListener = this.searchedVechicle.asObservable();
  public basevehicleListener = new BehaviorSubject<any>('652');
  public baseVehicleId = this.basevehicleListener.asObservable();
  public currentUrl = new BehaviorSubject<any>('/');
  public changeVehicle: EventEmitter<any> = new EventEmitter<any>();
  public loader = new BehaviorSubject<boolean>(false);
  public isloading = this.loader.asObservable();

  constructor(private http: HttpClient, public location: Location) {
    this.isYearEmpty = false;
    this.isMakeEmpty = false;
    this.isModelEmpty = false;
    this.isAllEmpty = false;
    this.location.onUrlChange((url: any) => {
      this.currentUrl.next(url);
    });
    this.searchedVechicle.next(
      JSON.parse(sessionStorage.getItem('selectedVehicle') || '{}')
    );
    if (sessionStorage.getItem('bvid')) {
      let session = JSON.parse(sessionStorage.getItem('bvid') || '{}');
      if (session) {
        this.basevehicleListener.next(session.baseVehicleId);
      }
    }
  }

  emitChangedVehicle(vehicle: any) {
    this.changeVehicle.emit(vehicle);
  }

  getChangeVehicle() {
    return this.changeVehicle;
  }

  checkValidProduct(product: any): void {
    if (product && product.year && product.make && product.model) {
      if (!product.year.year && !product.make.make && !product.model.model) {
        this.isAllEmpty = true;
      } else if (
        !product.year.year &&
        product.make.make &&
        product.model.model
      ) {
        this.isYearEmpty = true;
      } else if (
        product.year.year &&
        !product.make.make &&
        product.model.model
      ) {
        this.isMakeEmpty = true;
      } else if (
        product.year.year &&
        product.make.make &&
        !product.model.model
      ) {
        this.isModelEmpty = true;
      }
    }
  }

  // to get year data
  getYearAPI(): any {
    return this.http.get(
      `${environment.baseUrl}` + constObj.searchManagement.getYear
    );
  }

  // to get Make data
  getMakeAPI(year: any): any {
    return this.http.get(
      `${environment.baseUrl}` + constObj.searchManagement.getMake + year
    );
  }

  // to get Make data
  getModelAPI(year: any, makeId: any): any {
    return this.http.get(
      `${environment.baseUrl}` +
        constObj.searchManagement.getModel +
        year +
        '/' +
        makeId
    );
  }

  // to get Recent Vehicles Search
  getRecentAPI(sessionId: any): any {
    return this.http.get(
      `${environment.baseUrl}` +
        constObj.searchManagement.getRecent +
        '/' +
        sessionId
    );
  }

  saveRecentVehicles(reqObj: any) {
    return this.http.post(
      `${environment.baseUrl}` + constObj.searchManagement.getRecent,
      reqObj
    );
  }

  // to clear the recent all vehicles
  clearVehicles(sessionId: any): any {
    return this.http.put(
      `${environment.baseUrl}` + constObj.searchManagement.getRecent,
      sessionId
    );
  }

  //set Searched Vehicle
  setSearchedVehicle(value: any): any {
    this.searchedVechicle.next(value);
    sessionStorage.setItem('selectedVehicle', JSON.stringify(value));
  }

  //get Searched Vehicle
  getSearchedVehicle(): any {
    return this.searchedVechicle.asObservable();
  }

  //to get Sub Menu
  getSubMenu(): any {
    return this.http.get('../' + constObj.searchManagement.getSubMenu);
  }

  //to get Sub Menu List
  getSubMenuList(req: any): any {
    return this.http.get(
      `${environment.baseUrl}` + constObj.searchManagement.getSubMenuList + req
    );
  }

  // to get subModel in Maintanance
  getsubModelAPI(reqObj: any): any {
    return this.http.post(
      `${environment.baseUrl}` + constObj.maintenanceManagement.getSubModel,
      reqObj,
      { headers: { 'Access-Control-Allow-Origin': '*' } }
    );
  }

  // to get liters in Maintanance
  getLitersAPI(reqObj: any): any {
    return this.http.post(
      `${environment.baseUrl}` + constObj.maintenanceManagement.getLiters,
      reqObj
    );
  }

  // to get Fuel Type in Maintanance
  getFuelAPI(reqObj: any): any {
    return this.http.post(
      `${environment.baseUrl}` + constObj.maintenanceManagement.getFuelType,
      reqObj
    );
  }

  // to get # of Cyliners in Maintanance
  getCylinders(reqObj: any): any {
    return this.http.post(
      `${environment.baseUrl}` + constObj.maintenanceManagement.getCyliners,
      reqObj
    );
  }

  // to get Drive Types in Maintanance
  getDriveType(reqObj: any): any {
    return this.http.post(
      `${environment.baseUrl}` + constObj.maintenanceManagement.getDriveType,
      reqObj
    );
  }

  // to get Body Types in Maintanance
  getBodyType(reqObj: any): any {
    return this.http.post(
      `${environment.baseUrl}` + constObj.maintenanceManagement.getBodyType,
      reqObj
    );
  }

  // to get Transmission in Maintanance
  getTransmission(reqObj: any): any {
    return this.http.post(
      `${environment.baseUrl}` + constObj.maintenanceManagement.getTransmission,
      reqObj
    );
  }

  // to get Operating Condition in Maintanance
  getOpCondition(reqObj: any): any {
    return this.http.post(
      `${environment.baseUrl}` + constObj.maintenanceManagement.getOpCondition,
      reqObj
    );
  }

  // to get Full Chart in Maintanance
  getFullChart(reqObj: any): any {
    return this.http.post(
      `${environment.baseUrl}` + constObj.maintenanceManagement.getFullChart,
      reqObj
    );
  }

  // to get milege check values
  getMilegeAPI(reqObj: any): any {
    return this.http.post(
      `${environment.baseUrl}` + constObj.maintenanceManagement.milegeGetValue,
      reqObj
    );
  }

  // to get service information based on milege
  getMilegeServiceInformationAPI(reqObj: any): any {
    return this.http.post(
      `${environment.baseUrl}` +
        constObj.maintenanceManagement.milegeGetInformation,
      reqObj
    );
  }

  getRecommendedMilegeInformation(reqObj: any): any {
    return this.http.post(
      `${environment.baseUrl}` +
        constObj.maintenanceManagement.milegeRecommendedInfo,
      reqObj
    );
  }

  getViewFullChart(reqObj: any): any {
    return this.http.post(
      `${environment.baseUrl}` + constObj.maintenanceManagement.viewFullChart,
      reqObj
    );
  }

  // to get special tools notes
  getSpecialToolsNote(): any {
    return this.http.get(
      `${environment.baseUrl}` + constObj.repairManagement.getSpecialToolsNote
    );
  }

  getArticleList(req: any) {
    return this.http.get(
      `${environment.baseUrl}` + constObj.repairManagement.articleList + req
    );
  }

  // to get Table of Content
  getTableOfContent(id: any): any {
    return this.http.get(
      `${environment.baseUrl}` + constObj.repairManagement.tableOfContent + id
    );
  }

  // to get Base Vehicle
  getBaseVehicle(req: any): any {
    return this.http.post(
      `${environment.baseUrl}` + constObj.searchManagement.getBaseVehicle,
      req
    );
  }

  setBaseVehicleId(id: any) {
    if (id) {
      sessionStorage.setItem('bvid', JSON.stringify(id));
    }
  }

  getNonCompact(req: any): any {
    return this.http.post(
      `${environment.baseUrl}` + constObj.repairManagement.getSpecialToolsNote,
      req
    );
  }

  repairAudit(req: any, bvid: any): any {
    return this.http.post(
      `${environment.baseUrl}` + constObj.repairManagement.repairAudit + bvid,
      req
    );
  }

  //VIN
  getVinDataAPI(vin: any): any {
    return this.http.get(
      'https://vpic.nhtsa.dot.gov/api/vehicles/decodevinvalues/' +
        vin +
        '?format=json'
    );
  }

  // Bulletins

  //to get Bulletins List
  getBulletinsList(req: any): any {
    return this.http.get(
      `${environment.baseUrl}` +
        constObj.bulletinsManagement.bulletinsList +
        req
    );
  }

  getRecallsAPI(make: any, model: any, year: any): any {
    return this.http.get(
      `${environment.baseUrl}` +
        constObj.VINSearch.recalls +
        '/' +
        make +
        '/' +
        model +
        '/' +
        year
    );
  }

  getBulletinSystem(makeId: any): any {
    return this.http.get(
      `${environment.baseUrl}` + constObj.bulletinsManagement.systems + makeId
    );
  }

  getBulletinSymptoms(makeId: any): any {
    return this.http.get(
      `${environment.baseUrl}` + constObj.bulletinsManagement.symptoms + makeId
    );
  }

  getBulletinTroubleCode(makeId: any): any {
    return this.http.get(
      `${environment.baseUrl}` +
        constObj.bulletinsManagement.troubleCode +
        makeId
    );
  }
  getBulletinTroubleCodeFilter(makeId: any): any {
    return this.http.get(
      `${environment.baseUrl}` +
        constObj.bulletinsManagement.troubleCodeFilter +
        makeId
    );
  }

  getTSBSearch(req: any): any {
    return this.http.post(
      `${environment.baseUrl}` + constObj.bulletinsManagement.tsbSearch,
      req
    );
  }

  getAdvanceSearch(req: any, page: any): any {
    return this.http.post(
      `${environment.baseUrl}` +
        constObj.bulletinsManagement.advanceSearch +
        page,
      req
    );
  }

  getAllBulletinsList(bvid: any): any {
    return this.http.get(
      `${environment.baseUrl}` + constObj.bulletinsManagement.getAllList + bvid
    );
  }

  getAllAdvanceList(req: any, sorting: any) {
    return this.http.post(
      `${environment.baseUrl}` +
        constObj.bulletinsManagement.getAllAdvanceList +
        '/' +
        sorting,
      req
    );
  }

  getCurrentUrl(): any {
    return this.currentUrl.asObservable();
  }

  // Test Prep Quiz
  getQuiz(): any {
    return this.http.get('../' + constObj.testprepQuiz.questions);
  }
  pickQuestionPaper(id: any): any {
    return this.http.get('../assets/quiz/' + id + '.json');
  }

  // Labor Estimate
  getViewOperation(id: any): any {
    return this.http.get(
      `${environment.baseUrl}` + constObj.laborEstimate.system + '/' + id
    );
  }

  getSubSystem(id: any): any {
    return this.http.get(
      `${environment.baseUrl}` + constObj.laborEstimate.subSystem + id
    );
  }

  getSearchOperation(bvid: any, search: any): Observable<any> {
    return this.http.get(
      `${environment.baseUrl}` +
        constObj.laborEstimate.searchOperation +
        bvid +
        '/' +
        search
    );
  }

  getsearchTime(bvid: any, id: any, operationNameId: any, page: any) {
    return this.http.get(
      `${environment.baseUrl}` +
        constObj.laborEstimate.searchTimes +
        bvid +
        '/' +
        id +
        '/' +
        operationNameId +
        '/' +
        page
    );
  }

  submitFeedbackService(req: any) {
    return this.http.post(`${environment.baseUrl}` + constObj.help.submit, req);
  }
  getIp(): any {
    return this.http.get('http://api.ipify.org/?format=json');
  }

  submitRating(req: any) {
    return this.http.post(
      `${environment.baseUrl}` + constObj.repairManagement.rating,
      req
    );
  }

  submitFeedback(req: any) {
    return this.http.post(
      `${environment.baseUrl}` + constObj.repairManagement.feedback,
      req
    );
  }

  getvideoTableOfContent() {
    return this.http.get(
      `${environment.baseUrl}` + constObj.videoLibrary.tableOfContent
    );
  }

  getVideos(tocId: any) {
    return this.http.get(
      `${environment.baseUrl}` + constObj.videoLibrary.videos + tocId
    );
  }

  getAnimations(tocId: any) {
    return this.http.get(
      `${environment.baseUrl}` + constObj.videoLibrary.animations + tocId
    );
  }

  getflashAnimations(swf: any, xml: any) {
    return this.http.get(swf + '?xml=' + xml, {
      headers: { 'Access-Control-Allow-Origin': '*' },
    });
  }

  getMostPopularVideos() {
    return this.http.get(
      `${environment.baseUrl}` + constObj.videoLibrary.popularVideos
    );
  }

  auditVideos(req: any) {
    return this.http.post(
      `${environment.baseUrl}` + constObj.videoLibrary.auditVideo,
      req
    );
  }

  saveToken(token: any) {
    return this.http.get(
      `${environment.baseUrl}` +
        constObj.saveAuthToken.auth +
        '?auth_token=' +
        token
    );
  }

  getPrint(req: any) {
    return this.http.post(
      `${environment.baseUrl}` + constObj.searchManagement.printAudit,
      req
    );
  }
}
